import router from '../../plugins/router'
import SCard from '../../components/ui/s-card'
import { mapActions } from 'vuex'

export default {
  name: 'ReportCard',

  props: {
    report: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      unreadMessages: 0,
      maxLength: 100,
    }
  },

  async created() {
    const followups = await this.getFollowUps(this.report.id)
    this.unreadMessages = followups
      .filter(({ source }) => source === 'ADMIN')
      .filter(({ read }) => !read).length
  },

  components: {
    SCard,
  },

  methods: {
    ...mapActions(['getFollowUps']),

    openFollowUp(report) {
      if (report.status === 'ARCHIVED') {
        return
      }

      router.push({
        name: 'follow-up',
        params: { token: report.code, code: report.code, reportId: report.id },
      })
    },
  },

  computed: {
    hasReportPreviewButton() {
      const statusValid = ['ARCHIVED', 'PENDING_MATCH']
      return statusValid.includes(this.report.status)
    },

    isSolved() {
      return this.report.status === 'SOLVED'
    },

    hasUnreadMessages() {
      return this.unreadMessages > 0
    },
  },
}
