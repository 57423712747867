var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('s-card',{staticClass:"my-reports__card",class:_vm.report.status.toLowerCase()},[(_vm.hasUnreadMessages)?_c('span',{staticClass:"my-reports__card--badge"},[_vm._v(" "+_vm._s(_vm.unreadMessages)+" ")]):_vm._e(),_c('div',{staticClass:"my-reports__card--clickable",on:{"click":function($event){$event.preventDefault();return _vm.openFollowUp(_vm.report)}}},[_c('div',{staticClass:"my-reports__card--title"},[_c('section',[_c('div',[_c('h2',[_vm._v(_vm._s(_vm.$t('REPORT_CODE')))]),_vm._v(" "+_vm._s(_vm.report.code)+" ")]),_c('div',{staticClass:"status"},[_c('span',[_vm._v(_vm._s(_vm.$t(_vm.report.status)))])])])]),_c('div',{staticClass:"my-reports__card--title"},[_c('div',[_c('h2',[_vm._v(_vm._s(_vm.$t('REPORT_SUBJECT')))]),_c('p',[_vm._v(_vm._s(_vm._f("truncate")(_vm.report.description,_vm.maxLength)))])])]),_c('div',{staticClass:"my-reports__card--content"},[_c('div',{staticClass:"my-reports__card--content--item"},[_c('h3',[_vm._v(_vm._s(_vm.$t("CREATED_AT")))]),_c('p',[_vm._v(_vm._s(_vm._f("datetime")(_vm.report.createdAt)))])]),_c('div',{staticClass:"my-reports__card--content--item"},[_c('h3',[_vm._v(_vm._s(_vm.$t("UPDATED_AT")))]),_c('p',[_vm._v(_vm._s(_vm._f("datetime")(_vm.report.updatedAt)))])]),(_vm.report.publishedAt)?_c('div',{staticClass:"my-reports__card--content--item hide-xs"},[_c('h3',[_vm._v(_vm._s(_vm.$t("SENT_AT")))]),_c('p',[_vm._v(_vm._s(_vm._f("datetime")(_vm.report.publishedAt)))])]):_vm._e()]),(!_vm.isSolved && _vm.hasUnreadMessages)?_c('div',{staticClass:"my-reports__card--followup"},[_c('div',{staticClass:"my-reports__card--followup--item"},[_c('router-link',{staticClass:"button",attrs:{"to":{ name: 'follow-up',
          params: { 
            token: _vm.report.code, 
            code: _vm.report.code, 
            reportId: _vm.report.id 
          }
        }}},[_c('span',[_vm._v(_vm._s(_vm.$t('REPLY_REPORT')))])])],1)]):_vm._e()]),(_vm.hasReportPreviewButton)?_c('div',{staticClass:"my-reports__card--followup"},[_c('div',{staticClass:"my-reports__card--followup--item"},[_c('router-link',{staticClass:"button",attrs:{"to":{ name: 'follow-up',
        params: { token: _vm.report.code, code: _vm.report.code, reportId: _vm.report.id }}}},[_c('span',[_vm._v(_vm._s(_vm.$t('REVIEW_BEFORE_SEND_BTN')))])])],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }